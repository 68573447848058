/*
 * @Author: wxy
 * @Describe: 文件下载函数
 * @LastEditors: wxy
 * @LastEditTime: 2021-03-31 17:03:17
 */

import moment from "moment";

export default function downloadFile(res, fileName) {
  let timeNow = moment().format("LLL");
  return new Promise((resolve, reject) => {
    try {
      let blob = new Blob([res], { type: "application/vnd.ms-excel;charset=utf-8" }); //res为从后台返回的数据
      if (!fileName) {
        fileName = "Excel-" + timeNow;
      } else {
        fileName = fileName + "-" + timeNow;
      }
      if ("msSaveOrOpenBlob" in navigator) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        resolve(fileName);
      } else {
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
        resolve(fileName);
      }
    } catch (error) {
      reject(error);
    }
  });
}
