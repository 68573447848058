/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 21:51:36
 */

import { ref, readonly, reactive } from "vue";
import axios from "@/common/http/axios";
import downloadFile from "@/common/util/downloadFile";

// 默認配置
const option = {
  align: "center",
};

// 滚动配置
const scroll = {
  y: 500,
  scrollToFirstRowOnChange: true,
};

let paginationData = {
  current: 1,
  size: 20,
};

export default function useTable(_api = {}, _option) {
  const tableLoading = ref(false);

  // 分页配置
  const pagination = reactive({
    showSizeChanger: true,
    showQuickJumper: false,
    hideOnSinglePage: false,
    total: 0,
  });

  // api配置
  const api = Object.assign(
    { get: "", del: "", edit: "", info: "", export: "", custom: {} },
    _api
  );

  // 表单数据
  const _dataSourceRef = ref([]);
  const dataSourceRef = readonly(_dataSourceRef);

  // 获取数据
  const getData = async (option = { ...paginationData }, data = {}) => {
    tableLoading.value = true;
    // 获取数据
    let result = await axios.post(api.get, {
      ...option,
      ...data,
      ...api.custom,
    });
    settingData(result);
    tableLoading.value = false;
  };

  // change事件
  const tableChange = (pagination) => {
    paginationData.size = pagination.pageSize;
    paginationData.current = pagination.current;
    getData();
  };

  // 编辑数据
  const editData = async (api, data) => {
    tableLoading.value = true;
    await axios.post(api, data);
    getData();
  };

  // 設置數據
  const settingData = (result) => {
    pagination.total = result?.total ?? 0;
    _dataSourceRef.value = result?.records ?? [];
  };

  // 刷新数据
  const refresh = (data) => {
    getData(
      {
        current: paginationData.current,
        size: paginationData.size,
      },
      data
    );
  };

  const search = (data) => {
    paginationData.current = 1;
    paginationData.size = 20;
    getData({ ...paginationData }, data);
  };

  const exortTable = async (name, data) => {
    tableLoading.value = true;
    const result = await axios.post(
      api.export,
      {
        ...paginationData,
        ...data,
        ...api.custom,
      },
      false,
      "blob"
    );
    tableLoading.value = false;
    downloadFile(result, name);
  };

  getData();

  return {
    exortTable,
    search,
    refresh,
    tableLoading,
    dataSourceRef,
    option: Object.assign(option, _option),
    pagination,
    scroll,
    getData,
    editData,
    tableChange,
    settingData,
  };
}
