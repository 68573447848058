/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-04-17 19:50:56
 */

import { ref, reactive } from "vue";
import useTable from "@/composition/useTable";

export default function useTableFilter(filterApi = "", filterOptin) {

  const formLayout = ref("inline");

  const filterState = reactive(filterOptin);

  const filterFn = async (search) => {
    if (!filterApi) return;
    // 获取数据

    search({ ...filterState });

    // return axios.post(filterApi, { current: 1, size: 10, ...filterState });
  };

  return {
    formLayout,
    filterState,
    filterFn,
  };
}
